<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <!-- <vuexy-logo /> -->
        <h2 class="brand-text text-primary ml-1 d-none-mobile">
          <b-img
            style="width: 35%"
            :src="require('@/assets/images/app-images/logo-superapp.svg')"
            @click="$router.push({ path: '/' })"
          />
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5 left-section-login-page"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login"
            class="w-100"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5 right-section-login-page"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-0"
          >
            <img
              class="mt-5 mb-1 w-100"
              :src="require('@/assets/images/app-images/mom_r_wide.svg')"
            >
          </b-card-title>
          <div class="d-flex justify-content-end">
            <locale
              class="locale-wrapper"
              style="list-style: none"
            />
          </div>
          <b-form-group
            v-if="$route.meta.sharedType === 'provider'"
            label=""
          >
            <b-form-radio-group
              v-model="loginAs"
              :options="[
                { text: 'Provider', value: 'provider', disabled: false },
                { text: 'Agent', value: 'agent', disabled: false },
              ]"
              class="demo-inline-spacing mb-0 d-flex align-items-center"
              style="justify-content: space-evenly !important"
              value-field="value"
              text-field="text"
            />
          </b-form-group>
          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form
              class="auth-login-form mt-1"
              @submit.prevent
            >
              <b-form-group
                :label="$t('SIGN IN VIA')"
                class="text-center"
              >
                <b-form-radio-group
                  v-model="loginType"
                  class="demo-inline-spacing mb-1 justify-content-center no-margin-images"
                >
                  <b-form-radio value="email">
                    <img
                      class="icon-lable-radio"
                      :src="require('@/assets/images/app-images/icons8-mail-94.png')"
                    >
                  </b-form-radio>
                  <b-form-radio value="mobile">
                    <img
                      class="icon-lable-radio"
                      :src="require('@/assets/images/app-images/icons8-smartphone-94.png')"
                    >
                  </b-form-radio>
                  <b-form-radio value="whatsapp">
                    <img
                      class="icon-lable-radio"
                      :src="require('@/assets/images/app-images/icons8-whatsapp-94.png')"
                    >
                  </b-form-radio>
                </b-form-radio-group>
              </b-form-group>
              <!-- email -->
              <b-form-group
                v-if="loginType == 'email'"
                :label="$t('Email')"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                v-if="loginType == 'mobile' || loginType == 'whatsapp'"
                :label="$t('Phone Number')"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Phone"
                  rules="required"
                >
                  <VuePhoneNumberInput
                    v-model="userPhone"
                    :state="errors.length > 0 ? false : null"
                    placeholder="+91 0000000000"
                    @update="onUpdate"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">{{ $t('Password') }}</label>
                  <b-link
                    v-if="$route.meta.sharedType !== 'admin'"
                    :to="{
                      name: $route.meta.sharedType == 'provider' ? 'apps-provider-forget-password' : $route.meta.sharedType == 'shop' ? 'apps-shop-forget-password' : 'apps-forget-password',
                      query: $route.meta.sharedType == 'provider' && loginAs == 'agent' ? { agent: 1 } : {},
                    }"
                  >
                    <small>{{ $t('Forgot Password?') }}</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                @click="validationForm"
              >
                {{ $t('Sign in as') }} {{ $t(userTypeName) }}
              </b-button>
            </b-form>
          </validation-observer>
          <b-card-text
            v-if="$route.meta.sharedType === 'user' || $route.meta.sharedType === 'provider' || $route.meta.sharedType === 'shop'"
            class="text-center mt-2"
          >
            <span>{{ $t('New on our platform?') }} </span>
            <b-link :to="{ name: $route.meta.sharedType === 'provider' ? 'apps-provider-registration' : $route.meta.sharedType === 'shop' ? 'apps-shop-registration' : 'apps-registration' }">
              <span>&nbsp;{{ $t('Create an account') }}</span>
            </b-link>
          </b-card-text>
          <!-- divider -->
          <div
            class="divider my-2"
          >
            <div class="divider-text">
              {{ $t('or') }}
            </div>
          </div>

          <!-- social buttons -->
          <div
            class="auth-footer-btn d-flex justify-content-center"
          >
            <facebook-login
              app-id="518236876494478"
              class="face-book-login-button px-0"
              :login-label="''"
              @login="onLogin"
              @onLogout="onLogout"
              @get-initial-status="getUserData"
              @sdk-loaded="sdkLoaded"
              :logoutLabel="' '"
            />
            <div id="g_id_onload"></div>
            <div class="g_id_signin"></div>
          </div>
          <b-card-text class="text-left mt-2">
            <span>{{ $t('By continuing, you agree to our') }} </span>
            <b-link :to="{ path: '/terms-and-condition' }">
              <span>&nbsp;{{ $t('Terms of Use') }}</span>
            </b-link>
            {{ $t('and') }}
            <b-link :to="{ path: '/privacy-policy' }">
              <span>&nbsp;{{ $t('Privacy Policy') }}</span>
            </b-link>
          </b-card-text>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import facebookLogin from 'facebook-login-vuejs'
import { BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BFormRadioGroup, BFormRadio, BInputGroup, BCardText, BCardTitle, BImg, BForm, BButton } from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import VuePhoneNumberInput from 'vue-phone-number-input'
import store from '@/store/index'
import useJwt from '@/auth/jwt/useJwt'
import Locale from '@/@core/layouts/components/app-navbar/components/Locale.vue'
import { hideLoader, showDangerNotification, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import { fbLogin } from './init-facebook-sdk'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import jwt_decode from "jwt-decode";

export default {
  components: {
    BRow,
    BFormRadioGroup,
    BFormRadio,
    VuePhoneNumberInput,
    BCol,
    BLink,
    BFormGroup,
    facebookLogin,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    Locale,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      isConnected: false,
      FB: null,
      loginType: 'email',
      phoneNumberPayload: null,
      userEmail: '',
      passwordFieldType: 'password',
      userPhone: '',
      loginAs: 'provider',
      // eslint-disable-next-line import/no-unresolved
      sideImg: require('@/assets/images/app-images/login-animation.png'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      googlePrompt: null
    }
  },
  computed: {
    userTypeName() {
      if (this.$route.meta.sharedType === 'provider') {
        if (this.loginAs === 'provider') return 'Provider'
        return 'Agent'
      }
      if (this.$route.meta.sharedType === 'admin') return 'Admin'
      if (this.$route.meta.sharedType === 'shop') return 'Shop'
      return 'User'
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/app-images/login-animation.png')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  mounted() {
    window.socialLoginParams = payload => {
      this.makeSocialLogin(payload)
    }
    window.google.accounts.id.initialize({
      client_id: this.$superApp.google.clientId,
      callback: (res) => {
        this.OnGoogleAuthSuccess(res.credential)
      }
    });
    const parent = document.getElementById('g_id_onload');
    window.google.accounts.id.renderButton(parent, {
      theme: "outline",
      type: "icon",
      shape: "circle",
      size: "large",
    });
    window.google.accounts.id.prompt();

  },
  destroyed() {
    window.socialLoginParams = null
  },
  methods: {
    OnGoogleAuthSuccess (idToken) {
      const payload = jwt_decode(idToken)
      this.makeSocialLogin({
        social_unique_id: payload.sub,
        login_by: 'GOOGLE',
        first_name: payload.given_name,
        last_name: payload.family_name,
        email: payload.email,
        picture: payload.picture,
      })
    },
    OnGoogleAuthFail () {
      showDangerNotification(this, 'Failed to login !!')
    },
    getUserData() {
      if (!this.FB) return
      this.FB.api('/me', 'GET', { fields: 'id,first_name,last_name,email,link,gender,locale,picture' }, async response => {
        await this.onLogout()
        if (response.error) {
          showDangerNotification(this, 'Failed to login !!')
          return
        }else if(response.email == null || response.email == '' || typeof response.email == typeof undefined){
          showDangerNotification(this, 'Email address not found on your Facebook account try alternate method for Login (Manual/Google)!!')
          return false
        }
        if (window.socialLoginParams) {
          window.socialLoginParams({
            social_unique_id: response.id,
            login_by: 'FACEBOOK',
            first_name: response.first_name,
            last_name: response.last_name,
            email: response.email,
            picture: response.picture && response.picture.data ? response.picture.data.url : '',
          })
        }
      })
    },
    sdkLoaded(payload) {
      this.FB = payload.FB
      if (this.isConnected) this.getUserData()
    },
    onLogin() {
      this.isConnected = true
      this.getUserData()
    },
    onLogout() {
      if (!this.FB) return
      this.isConnected = false
      try {
        this.FB.logout()
      }catch {

      }
    },
    onUpdate(payload) {
      this.phoneNumberPayload = payload
    },
    switchLogin(formData) {
      if (this.$route.meta.sharedType === 'provider') {
        if (this.loginAs === 'provider') {
          return useJwt.providerLogin(formData)
        }
        return useJwt.agentLogin(formData)
      }
      if (this.$route.meta.sharedType === 'shop') {
        return useJwt.shopLogin(formData)
      }
      if (this.$route.meta.sharedType === 'admin') {
        return useJwt.adminLogin(formData)
      }
      return useJwt.login(formData)
    },
    switchSocailLogin(formData) {
      if (this.$route.meta.sharedType === 'provider') {
          return useJwt.providerSocailLogin(formData)
      }
      if (this.$route.meta.sharedType === 'shop') {
        return useJwt.shopSocailLogin(formData)
      }
      return useJwt.loginSocial(formData)
    },
    makeSocialLogin(payload) {
      const formData = new FormData()
      formData.append('social_unique_id', payload.social_unique_id)
      formData.append('login_by', payload.login_by)
      formData.append('first_name', payload.first_name)
      formData.append('last_name', payload.last_name)
      formData.append('filter', 'email')
      formData.append('email', payload.email)
      formData.append('picture', payload.picture)
      formData.append('salt_key', window.SuperApp.getters.saltKey())
      window.localStorage.setItem('userSocialInfo', JSON.stringify(payload))
      this.switchSocailLogin(formData)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.setLoginInformations(data)
          } else if (data.responseData.status) {
            this.setLoginInformations(data)
          } else {
            showDangerNotification(this, data.message)
            if (data.responseData.validators && data.responseData.validators.length > 0) {
              if (this.$route.meta.sharedType === 'user') {
                this.$router.push({ name: 'apps-registration' })
              }
            }
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    validationForm() {
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          const formData = new FormData()
          if (this.loginType === 'email') {
            formData.append('filter', 'email')
            formData.append('email', this.userEmail)
          }
          if (this.loginType === 'mobile' || this.loginType == 'whatsapp') {
            formData.append('filter', 'phone')
            formData.append('country_code', this.phoneNumberPayload.countryCallingCode)
            formData.append('mobile', this.phoneNumberPayload.nationalNumber)
          }
          formData.append('password', this.password)
          formData.append('salt_key', window.SuperApp.getters.saltKey())
          if (this.$route.meta.sharedType === 'admin') {
            formData.append('role', 'Admin')
          }
          showLoader()
          this.switchLogin(formData)
            .then(response => {
              hideLoader()
              const responseLogin = response.data
              if (responseLogin.statusCode === '200') {
                this.setLoginInformations(responseLogin)
              } else {
                showDangerNotification(this, 'Failed to Loginss')
              }
            })
            .catch(error => {
              showErrorNotification(this, error)
            })
        }
      })
    },
    setLoginInformations(responseLogin) {
      useJwt.setToken(responseLogin.responseData.access_token)
      useJwt.setRefreshToken(responseLogin.responseData.access_token)
      window.SuperApp.getters.setUserInfo(JSON.stringify(responseLogin.responseData.user))
      window.SuperApp.getters.setUserType(this.$route.meta.sharedType ? this.$route.meta.sharedType : 'user')
      window.SuperApp.getters.setUserAccess(responseLogin.responseData.access_token)
      showSuccessNotification(this, 'Logged in successfully')
      window.SuperApp.getters.setUserLoggedIn(true)
      if (responseLogin.responseData.user.city_id) {
        localStorage.setItem('userSelectedCityId', responseLogin.responseData.user.city_id)
      }
      if (!document.body.classList.contains('modal-open')) {
        window.SuperApp.helper.changeTranslation(this, window.SuperApp.getters.userLang())
        if (this.$route.query.returnUrl) {
          this.$router.push({ path: this.$route.query.returnUrl })
        } else if (window.SuperApp.getters.isUser()){
          this.$router.push({ name: 'user-dashboard' })
        }
        else if (window.SuperApp.getters.isProvider() && !window.SuperApp.getters.isAgent()){
          this.$router.push({ name: 'provider-requests' })
        }
        else if (window.SuperApp.getters.isProvider() && window.SuperApp.getters.isAgent()){
          this.$router.push({ name: 'provider-agent-dashboard' })
        }
        else if (window.SuperApp.getters.isStore()){
          this.$router.push({ name: 'shop-dashboard' })
        }
        else if (window.SuperApp.getters.isAdmin()){
          this.$router.push({ name: 'admin-cmspages-faq' })
        }
        window.SuperApp.helper.requestPermission()
      } else {
        window.location.reload()
      }
    },
    loginWithFacebook() {
      fbLogin()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.no-margin-images {
  margin-top: 8px !important;

  .custom-control-inline {
    margin-top: 10px !important;
  }
}

.auth-footer-btn {
  button {
    border: 0;
    background-color: transparent !important;
  }
}
</style>
